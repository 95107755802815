<template>
    <div class="modal-profile-item-selector bg-black">
        <QnaHeader
            :leftBtnHandler="leftBtnHandler"
            :leftBtnIcon="leftBtnIcon"
            :showLeftBtn="true"
            :title="options.title"
        />
        <div class="progress-bar-container" v-if="model === 'education'">
            <div class="progress" :style="{ width: `${(nowStep / (lastStep + 1)) * 100}%` }" />
        </div>
        <div class="subtitle" v-html="subTitle" />
        <div class="input-wrapper items-center">
            <img :src="require('@/assets/images/icon-search.png')" class="m-r-4" />
            <input ref="searchValue" @input="onInput" :placeholder="placeholder" />
            <i v-if="searchValue" @click="refreshSearch" class="material-icons">cancel</i>
        </div>
        <ul class="result flex-fill" v-if="profileItems.length">
            <li
                @click="onClickItem(item)"
                class="item flex-row items-center flex-between"
                :class="{ selected: item.$$selected }"
                style="padding-left: 16px"
                :key="item.id"
                v-for="item in profileItems"
            >
                <div v-html="$translate(getVal(item))" />
            </li>
        </ul>
        <div class="please-search" v-if="!profileItems.length && !noResult" v-html="voidSearchWord" />
    </div>
</template>

<script>
import debounce from '@/modules/debounce'
import profileService from '@/services/profile'

export default {
    name: 'ModalSearchList',
    props: ['options'],
    data: () => ({
        searchValue: null,
        profileItems: [],
        nowStep: 0,
        noResult: false,
        selected: [],
    }),
    computed: {
        model() {
            return this.options.model
        },
        leftBtnIcon() {
            if (this.model === 'education') return this.nowStep ? 'arrow_back' : 'close'

            return 'close'
        },
        placeholder() {
            if (this.model === 'education') {
                return this.nowStep === 0
                    ? this.$translate('PLACEHOLDER_SEARCH_EDUCATION')
                    : this.nowStep === 1
                    ? this.$translate('PLACEHOLDER_SEARCH_EDUCATION_COLLEGE')
                    : this.$translate('PLACEHOLDER_SEARCH_EDUCATION_MAJOR')
            }

            return this.$translate(this.model.toUpperCase())
        },
        subTitle() {
            if (this.model === 'education') return '서류 인증도 필요하니 정확한 학력을 선택해주세요.'
            else if (this.model === 'company') return '서류 인증도 필요하니 정확한 직장을 입력해주세요.'
            else if (this.model === 'job') return '서류 인증도 필요하니 정확한 직업을 입력해주세요.'
            else return '서류 인증도 필요하니 정확한 소득을 입력해주세요.'
        },
        lastStep() {
            if (this.model === 'education') return 2

            return 0
        },
        voidSearchWord() {
            if (this.model === 'education') {
                if (this.nowStep === 0) {
                    return `<div><p>학부(학교명)을 검색해 주세요.</p><p>ex. 서울대, 서울대학교 등</p></div>`
                } else if (this.nowStep === 1) {
                    return `<div><p>단과대를 검색해 주세요.</p><p>ex. 경영대, 디자인대 등</p></div>`
                } else {
                    return `<div><p>학과를 검색해 주세요.</p><p>ex. 경영학, 경영학과 등</p></div>`
                }
            } else if (this.model === 'company') {
                return `<div><p>직장을 검색해 주세요.</p><p>ex. 삼성전자, 삼성정밀화학 등</p></div>`
            } else if (this.model === 'job') {
                return `<div><p>직업을 검색해 주세요.</p><p>ex. 의사, 변호사, 군의관 등</p></div>`
            }

            return '아무거나 입력하시오'
        },
    },
    methods: {
        onClickClose() {
            this.$emit('close')
        },
        leftBtnHandler() {
            if (this.model === 'education') {
                if (this.nowStep === 0) this.$emit('close')
                else {
                    this.nowStep -= 1
                    this.selected = this.selected.slice(0, this.selected.length - 1)
                    this.refreshSearch()
                }
            } else {
                this.$emit('close')
            }
        },
        refreshSearch() {
            const input = this.$refs.searchValue
            input.value = ''

            this.searchValue = ''
            this.profileItems = []
            this.noResult = false
        },
        search: debounce(async function (val) {
            try {
                if (this.model === 'education') {
                    if (this.nowStep === 0) {
                        this.profileItems = await profileService.universities({ schoolTypeId: 7, name: val })
                    } else if (this.nowStep === 1) {
                        this.profileItems = await profileService.college({ name: val })
                    } else {
                        this.profileItems = await profileService.majors({ name: val })
                    }
                } else if (this.model === 'company') {
                    this.profileItems = await profileService.companies({
                        jobCategoryId: this.options.jobCategory,
                        name: val,
                    })
                } else if (this.model === 'job') {
                    this.profileItems = await profileService.jobs({ name: val })
                }

                this.noResult = this.profileItems.length === 0
                this.profileItems = [{ name: val, id: 0 }, ...this.profileItems]
            } catch (e) {
                this.$toast.error(e.data)
            }
        }, 200),
        onInput(e) {
            this.searchValue = e.target.value

            if (!this.searchValue) {
                this.profileItems = []
            } else {
                this.search(this.searchValue)
            }
        },
        async onClickItem(item) {
            if (this.model === 'education') {
                this.refreshSearch()
                this.$set(item, '$$selected', !item.$$selected)
            }

            setTimeout(async () => {
                const dom = document.querySelector('.modal-profile-item-selector')
                dom.scrollTo(0, 0)

                if (this.nowStep < this.lastStep) {
                    this.nowStep += 1
                    setTimeout(() => {
                        this.selected = [...this.selected, item]
                    }, 100)
                } else {
                    if (this.model === 'company') {
                        const res = await this.$modal.custom({
                            component: 'ModalCompanyAddInfo',
                        })
                        if (!res) return
                        item = { ...res, ...item }
                    }

                    this.selected = [...this.selected, item]
                    this.$emit('close', this.selected)
                }
            }, 200)
        },
        getVal(o) {
            if (o.id === 0) return `<span class='c-primary'>${o.name}</span> (으)로 입력하기`

            return o.name
        },
    },
}
</script>

<style scoped lang="scss">
.progress-bar-container {
    width: 100%;
    height: 2px;
    background: #666b70;

    .progress {
        height: 100%;
        background: $blue-primary;
    }
}
.input-wrapper {
    //padding-top: 24px;
    input {
        color: white;
    }
}
.subtitle {
    text-align: center;
    padding: 24px 0;
}
.result {
    padding: 0 16px;

    .item {
        padding: 16px 0;
    }
}
.modal-profile-item-selector {
    color: white;
}
.please-search {
    text-align: center;
    height: calc(100vh - 300px);
    @include center;
    color: $grey-05;
}
</style>
